// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../resources/images/scenarios.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Jarvis_container__6LW03::-webkit-scrollbar,\n.Jarvis_container__6LW03 {\n  display: flex;\n  position: relative;\n  align-items: flex-end;\n  justify-content: center;\n  gap: 14px;\n  margin-top: 15px;\n  color: #d3ffff;\n  text-shadow: #d3ffff 0 0 3px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 70%;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n\n.Jarvis_container__6LW03 > p {\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/jarvis/Jarvis.module.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;EACvB,SAAS;EACT,gBAAgB;EAChB,cAAc;EACd,4BAA4B;EAC5B,yDAA6D;EAC7D,oBAAoB;EACpB,kCAAkC;EAClC,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".container::-webkit-scrollbar,\n.container {\n  display: flex;\n  position: relative;\n  align-items: flex-end;\n  justify-content: center;\n  gap: 14px;\n  margin-top: 15px;\n  color: #d3ffff;\n  text-shadow: #d3ffff 0 0 3px;\n  background-image: url(\"../../resources/images/scenarios.gif\");\n  background-size: 70%;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n\n.container > p {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Jarvis_container__6LW03"
};
export default ___CSS_LOADER_EXPORT___;
