// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ADT3DViewerCard_card__0jRvd {\n  position: relative;\n  height: 100%;\n}\n\n.ADT3DViewerCard_operatingHours__WfHSH {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  gap: 10px;\n  top: 18px;\n  left: 18px;\n  z-index: 1000;\n}\n\n.ADT3DViewerCard_operatingHoursText__IP9vC {\n  font-family: \"Segoe UI Semibold\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 10px;\n  line-height: 14px;\n  color: #ffffff;\n}\n", "",{"version":3,"sources":["webpack://./src/components/adt-3d-viewer-card/ADT3DViewerCard.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;EACT,SAAS;EACT,UAAU;EACV,aAAa;AACf;;AAEA;EACE,4CAA4C;EAC5C,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".card {\n  position: relative;\n  height: 100%;\n}\n\n.operatingHours {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  gap: 10px;\n  top: 18px;\n  left: 18px;\n  z-index: 1000;\n}\n\n.operatingHoursText {\n  font-family: \"Segoe UI Semibold\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 10px;\n  line-height: 14px;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "ADT3DViewerCard_card__0jRvd",
	"operatingHours": "ADT3DViewerCard_operatingHours__WfHSH",
	"operatingHoursText": "ADT3DViewerCard_operatingHoursText__IP9vC"
};
export default ___CSS_LOADER_EXPORT___;
