// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Page_pageContainer__Yp6sD {\n  display: grid;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  grid-template-columns: -webkit-min-content 4fr 1fr;\n  grid-template-columns: min-content 4fr 1fr;\n  grid-template-rows: -webkit-min-content 1fr -webkit-min-content;\n  grid-template-rows: min-content 1fr min-content;\n  grid-template-areas:\n    \"left top right\"\n    \"left center right\"\n    \"left bottom right\";\n  background: radial-gradient(\n    circle farthest-corner at center center,\n    #212b6e 0%,\n    #000 100%\n  );\n}\n", "",{"version":3,"sources":["webpack://./src/components/shared/page/Page.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,YAAY;EACZ,gBAAgB;EAChB,kDAA0C;EAA1C,0CAA0C;EAC1C,+DAA+C;EAA/C,+CAA+C;EAC/C;;;uBAGqB;EACrB;;;;GAIC;AACH","sourcesContent":[".pageContainer {\n  display: grid;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n  grid-template-columns: min-content 4fr 1fr;\n  grid-template-rows: min-content 1fr min-content;\n  grid-template-areas:\n    \"left top right\"\n    \"left center right\"\n    \"left bottom right\";\n  background: radial-gradient(\n    circle farthest-corner at center center,\n    #212b6e 0%,\n    #000 100%\n  );\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "Page_pageContainer__Yp6sD"
};
export default ___CSS_LOADER_EXPORT___;
