const { BABYLON } = window;

export const MQTT_RECEPTION_TOPIC = "scenescape/regulated/scene/reception";
export const MQTT_QUEUING_TOPIC = "scenescape/regulated/scene/Queuing";
export const MQTT_SHOWROOM_TOPIC = "scenescape/regulated/scene/showroom";

export const MQTT_SUBSCRIBE_TOPIC = MQTT_SHOWROOM_TOPIC


// THIS TOPIC IS USED FOR TESTING.
// "scenescape/regulated/scene/Queuing"

// export const RESOLUTION_X = 1440;
// export const RESOLUTION_Y = 1200;

export const RESOLUTION_X = 3850;
export const RESOLUTION_Y = 5450;

// export const ROOM_DIMENSION_X = 8.75;
// export const ROOM_DIMENSION_Y = 10;
export const ROOM_DIMENSION_X = 3.85;
export const ROOM_DIMENSION_Y = 5.45;
export const ROOM_DIMENSION_Z = 1;

export const ROOM_DIMENSION_SCALE = new BABYLON.Vector3(.75, .75, 1);
// export const ROOM_DIMENSION_SCALE = new BABYLON.Vector3(1.35, 1.6, 1.35);

export const SPHERE_SHAPE_CONFIG = { diameter: 1, segments: 32 };
export const SPHERE_COLOR = new BABYLON.Color3(1.0, 0.2, 0.7); // pink
