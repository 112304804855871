// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown_select__dMqjB {\n  background: transparent;\n  border: 1px #7588bd solid;\n  border-radius: 4px;\n\n  font-family: \"Segoe UI\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #7588bd60;\n\n  padding: 10px 0 7px 10px;\n}\n\n.Dropdown_select__dMqjB::-webkit-input-placeholder {\n  color: #7588bd;\n}\n\n.Dropdown_select__dMqjB::placeholder {\n  color: #7588bd;\n}\n\nselect:focus {\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;;EAElB,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;;EAEhB,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAFA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".select {\n  background: transparent;\n  border: 1px #7588bd solid;\n  border-radius: 4px;\n\n  font-family: \"Segoe UI\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #7588bd60;\n\n  padding: 10px 0 7px 10px;\n}\n\n.select::placeholder {\n  color: #7588bd;\n}\n\nselect:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "Dropdown_select__dMqjB"
};
export default ___CSS_LOADER_EXPORT___;
