// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar_sidebarContainer__MP20Z {\n  grid-area: right;\n  display: flex;\n  flex-direction: column;\n  justify-self: end;\n  height: 100vh;\n  width: 360px;\n  padding: 0 15px;\n  overflow: hidden;\n  gap: 10px;\n  background: linear-gradient(#000000, #1c2555);\n}\n", "",{"version":3,"sources":["webpack://./src/components/shared/sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,SAAS;EACT,6CAA6C;AAC/C","sourcesContent":[".sidebarContainer {\n  grid-area: right;\n  display: flex;\n  flex-direction: column;\n  justify-self: end;\n  height: 100vh;\n  width: 360px;\n  padding: 0 15px;\n  overflow: hidden;\n  gap: 10px;\n  background: linear-gradient(#000000, #1c2555);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebarContainer": "Sidebar_sidebarContainer__MP20Z"
};
export default ___CSS_LOADER_EXPORT___;
