// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalComponent_modal__OOj0W {\n  display: none;\n  flex-direction: column;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 225px;\n  padding: 64px;\n  padding-top: 110px;\n  gap: 16px;\n  /* As 3d scene controls have 999 */\n  z-index: 1000;\n  background-color: rgb(11, 3, 24);\n\n  &.ModalComponent_isActive__kBm-s {\n    display: flex;\n  }\n}\n\n.ModalComponent_back__xlODV {\n  align-self: flex-start;\n  align-items: center;\n  background-color: #2F74F6;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  font-size: 14px;\n  padding: 6px 12px;\n}\n\n.ModalComponent_title__40MU4 {\n  font-size: 28px;\n  color: #fff;\n  margin: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/modal/ModalComponent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,SAAS;EACT,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,kCAAkC;EAClC,aAAa;EACb,gCAAgC;;EAEhC;IACE,aAAa;EACf;AACF;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,SAAS;AACX","sourcesContent":[".modal {\n  display: none;\n  flex-direction: column;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 225px;\n  padding: 64px;\n  padding-top: 110px;\n  gap: 16px;\n  /* As 3d scene controls have 999 */\n  z-index: 1000;\n  background-color: rgb(11, 3, 24);\n\n  &.isActive {\n    display: flex;\n  }\n}\n\n.back {\n  align-self: flex-start;\n  align-items: center;\n  background-color: #2F74F6;\n  color: #fff;\n  border: none;\n  border-radius: 4px;\n  font-size: 14px;\n  padding: 6px 12px;\n}\n\n.title {\n  font-size: 28px;\n  color: #fff;\n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "ModalComponent_modal__OOj0W",
	"isActive": "ModalComponent_isActive__kBm-s",
	"back": "ModalComponent_back__xlODV",
	"title": "ModalComponent_title__40MU4"
};
export default ___CSS_LOADER_EXPORT___;
