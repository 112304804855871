import { useEffect, useRef, useState } from "react";
import styles from './VideoOverlay.module.css';
import WHEPClient, { boundingBox } from "../../helpers/WHEPClient";
import noConnectionPlaceholder from "../../resources/images/disconnected.svg";
import loading from "../../resources/images/loading.gif";
import { IVideoOverlayProps }  from "../../ts/general";
import { stopStreamedVideo } from "../../helpers/stopStreamedVideo";


// const VideoSwitch = ({ enableOverlay, handleChange }: { enableOverlay: boolean; handleChange: any; }) => {
//     return (
//         <div className={styles.labelWrapper}>
//             {`Tracking overlay is ${enableOverlay ? 'enabled' : 'disabled'}`}
//             <label className={styles.switch}>
//                 <input id="enableEverlay" type="checkbox" onChange={handleChange} />
//                 <span className={cn(styles.slider, styles.round)}></span>
//             </label>
//         </div>
//     );
// }

const VideoOverlay = ({ src, overlaySrc, poster }: IVideoOverlayProps) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isDisconnected, setIsDisconnected] = useState(false);
    const [loadingState, isLoading] = useState(true);

    useEffect(() => {
        const initWHEPClient = (video: null, transformFn: any, streamUrl: string) => new WHEPClient(video, transformFn, streamUrl);
        const transfromFn = boundingBox();

        initWHEPClient(
            videoRef.current as any,
            transfromFn,
            src,
        )
        
        if(!videoRef.current){
            return
          }

          const handleError = () => {
            const videoNetworkState = videoRef.current?.networkState
            if(videoNetworkState === 1){
              stopStreamedVideo(videoRef.current as any);
              setIsDisconnected(true);
            }
          };
      
          const handleLoadingScreen = () => {
            const videoReadyState = videoRef.current?.readyState;
            if(videoReadyState === 0){
              isLoading(false);
              setIsDisconnected(true);
            }
          };
          
         videoRef.current?.addEventListener('volumechange', handleLoadingScreen);
         videoRef.current?.addEventListener('suspend', handleError)

        return () => {
          videoRef.current?.removeEventListener('volumechange', handleLoadingScreen);
          videoRef.current?.removeEventListener('suspend', handleError);
          document.removeEventListener('message-received', () => { });
          document.removeEventListener('toggle-overlay', () => { });
        }
       
        
    }, [isDisconnected, loadingState]);

    // const handleChange = (e: { target: { checked: any; }; }) => {
    //     const { checked } = e.target;
    //     // document.dispatchEvent(new CustomEvent('toggle-overlay', { detail: checked }));
    //     setEnableOverlay(checked);
    // }
  
  useEffect(() => {
    // fixed mobile issue with autoplay
    videoRef.current?.play();
  }, []);

    return (
        <div className={styles.wrapper}>
            <video ref={videoRef} id="video" autoPlay playsInline muted poster={loadingState ? loading : (isDisconnected ? noConnectionPlaceholder : '')} />
        </div>
    );
}

export default VideoOverlay
