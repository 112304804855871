// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Navigation_container__O3hxQ {\n  display: flex;\n  gap: 22px;\n  width: 100%;\n  flex-direction: column;\n}\n\n.Navigation_routeContainer__ywbI7 {\n  display: flex;\n  gap: 9px;\n  flex-direction: row;\n  cursor: pointer;\n}\n\n.Navigation_infoContainer__pleCK {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.Navigation_title__X90kg {\n  font-family: \"Segoe UI Semibold\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: #d6d6d6;\n}\n\n.Navigation_subtitle__c8Q7N {\n  font-family: \"Segoe UI\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: #d6d6d6;\n}\n", "",{"version":3,"sources":["webpack://./src/components/navigation/Navigation.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,4CAA4C;EAC5C,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".container {\n  display: flex;\n  gap: 22px;\n  width: 100%;\n  flex-direction: column;\n}\n\n.routeContainer {\n  display: flex;\n  gap: 9px;\n  flex-direction: row;\n  cursor: pointer;\n}\n\n.infoContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.title {\n  font-family: \"Segoe UI Semibold\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 14px;\n  line-height: 20px;\n  color: #d6d6d6;\n}\n\n.subtitle {\n  font-family: \"Segoe UI\", sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n  color: #d6d6d6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Navigation_container__O3hxQ",
	"routeContainer": "Navigation_routeContainer__ywbI7",
	"infoContainer": "Navigation_infoContainer__pleCK",
	"title": "Navigation_title__X90kg",
	"subtitle": "Navigation_subtitle__c8Q7N"
};
export default ___CSS_LOADER_EXPORT___;
