// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Tree_tree__\\+WnMw {\n  list-style-type: none;\n  padding: 0;\n  /* margin-top: 10px; */\n}\n\n.Tree_node__rHLaI {\n  position: relative;\n}\n\n.Tree_labelContainer__0wMqp {\n  display: flex;\n  align-items: center;\n}\n\n.Tree_label__ubVXj {\n  font-family: \"Segoe UI Bold\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 22px;\n  color: #ffffff;\n  cursor: pointer;\n}\n\n.Tree_disabled__LYRXQ {\n  cursor: default;\n  color: #ffffff60;\n}\n\n.Tree_labelOpen__Tf39M {\n  color: #479ef5;\n}\n\n.Tree_branch__cmAv- {\n  margin-left: 20px;\n}\n\n.Tree_cursorPointer__bejGX {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/tree/Tree.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".tree {\n  list-style-type: none;\n  padding: 0;\n  /* margin-top: 10px; */\n}\n\n.node {\n  position: relative;\n}\n\n.labelContainer {\n  display: flex;\n  align-items: center;\n}\n\n.label {\n  font-family: \"Segoe UI Bold\", sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 22px;\n  color: #ffffff;\n  cursor: pointer;\n}\n\n.disabled {\n  cursor: default;\n  color: #ffffff60;\n}\n\n.labelOpen {\n  color: #479ef5;\n}\n\n.branch {\n  margin-left: 20px;\n}\n\n.cursorPointer {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tree": "Tree_tree__+WnMw",
	"node": "Tree_node__rHLaI",
	"labelContainer": "Tree_labelContainer__0wMqp",
	"label": "Tree_label__ubVXj",
	"disabled": "Tree_disabled__LYRXQ",
	"labelOpen": "Tree_labelOpen__Tf39M",
	"branch": "Tree_branch__cmAv-",
	"cursorPointer": "Tree_cursorPointer__bejGX"
};
export default ___CSS_LOADER_EXPORT___;
