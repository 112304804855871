// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Delimiter_vertical__BBC5k {\n  width: 1px;\n  height: 100%;\n}\n\n.Delimiter_horizontal__VuxX\\+ {\n  height: 1px;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/delimiter/Delimiter.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;EACX,WAAW;AACb","sourcesContent":[".vertical {\n  width: 1px;\n  height: 100%;\n}\n\n.horizontal {\n  height: 1px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertical": "Delimiter_vertical__BBC5k",
	"horizontal": "Delimiter_horizontal__VuxX+"
};
export default ___CSS_LOADER_EXPORT___;
